/* App font */

@font-face {
  font-family: 'GT Pressura';
  font-weight: normal;
  src: url('./assets/fonts/GT-Pressura-Regular.woff2') format('woff2'),
    url('./assets/fonts/GT-Pressura-Regular.woff') format('woff');
}

@font-face {
  font-family: 'GT Pressura';
  font-weight: bold;
  src: url('./assets/fonts/GT-Pressura-Bold.woff2') format('woff2'),
    url('./assets/fonts/GT-Pressura-Bold.woff') format('woff');
}
